import './App.css';
import { 
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Secret from './Secret/Secret';
import Tools from './Tools/Tools';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/secret/:code" element={ <Secret /> } />
        <Route path="/" element={ <Tools /> } />
      </Routes>
    </Router>
  );
}

export default App;