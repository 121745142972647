import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Secret = () => {
    const   [shown, setShown] = useState(false),
            [secret, setSecret] = useState('');

    let { code } = useParams();

    const secretHandler = () => {
        setShown(true);

        fetch(`https://tools.mf.bmstu.ru/api/secret?id=${code}`)
            .then(response => response.json())
            .then(object => {
                object.error
                    ? setSecret('Что-то пошло не так')
                    : setSecret(object.secret);
            })
            .catch(err => {
                setSecret('Что-то пошло не так');
                console.log(err);
            });
    }

    return(
        <div>
            <Helmet>
                <title>Secret</title>
                <meta name='description' content='One Time Secret' />
            </Helmet>

            <h1>Этот секрет можно узнать только 1&nbsp;раз</h1>

            {shown ? secret : <button onClick={secretHandler}>Показать пароль</button>}
        </div>
    );
}

export default Secret;