// import { Link } from 'react-router-dom';

const Tools = () => {
    return(
        <div>
            <h1>Всякие тулзы 🙃</h1>
            {/* <nav>
                <ul>
                    <li>
                        <Link to="/secret">Генератор паролей</Link>
                    </li>
                </ul>
            </nav> */}
        </div>
    );
}

export default Tools;